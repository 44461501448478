import "core-js/modules/es.array.push.js";
import { resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, unref as _unref, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, withCtx as _withCtx, createTextVNode as _createTextVNode, Fragment as _Fragment, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";
import _imports_0 from '@/assets/images/auth_1.png';
import _imports_1 from '@/assets/images/auth_2.png';
import _imports_2 from '@/assets/images/auth1.png';
import _imports_3 from '@/assets/images/auth3.png';
const _withScopeId = n => (_pushScopeId("data-v-167be2ba"), n = n(), _popScopeId(), n);
const _hoisted_1 = {
  key: 0,
  class: "auth"
};
const _hoisted_2 = {
  class: "mb-8"
};
const _hoisted_3 = {
  class: "mb-8"
};
const _hoisted_4 = {
  class: "auth-form__item"
};
const _hoisted_5 = {
  class: "mb-8"
};
const _hoisted_6 = {
  class: "auth-form__item"
};
const _hoisted_7 = {
  class: "mb-8"
};
const _hoisted_8 = {
  class: "auth-photo"
};
const _hoisted_9 = {
  class: "auth-photo__item"
};
const _hoisted_10 = ["src"];
const _hoisted_11 = {
  key: 1,
  src: _imports_0,
  alt: ""
};
const _hoisted_12 = {
  class: "auth-photo__item"
};
const _hoisted_13 = ["src"];
const _hoisted_14 = {
  key: 1,
  src: _imports_1,
  alt: ""
};
const _hoisted_15 = {
  class: "auth"
};
const _hoisted_16 = {
  class: "auth-status"
};
const _hoisted_17 = {
  key: 0,
  src: _imports_2,
  alt: ""
};
const _hoisted_18 = {
  key: 1,
  src: _imports_3,
  alt: ""
};
const _hoisted_19 = {
  key: 2
};
const _hoisted_20 = {
  key: 3
};
import { router } from '@/router';
import { uploadFile, addAuth, authStatus } from '@/http';
import { ref } from 'vue';
import { IMG_URL } from '@/config';
import { showToast } from 'vant';
import { useI18n } from 'vue-i18n';
export default {
  __name: 'auth',
  setup(__props) {
    const {
      t
    } = useI18n();
    const authData = ref(-1);
    const _authStatus = () => {
      authStatus().then(({
        data
      }) => {
        authData.value = data;
      });
    };
    _authStatus();
    const _addAuth = () => {
      addAuth({
        ...formData.value,
        type: type.value.value,
        front_img: frontImg.value,
        back_img: backImg.value
      }).then(({
        code,
        msg
      }) => {
        showToast(msg);
        if (code === 200) {
          router.push('/me');
        }
      });
    };
    const formData = ref({
      real_name: '',
      idcret_num: ''
    });
    const frontImg = ref('');
    const backImg = ref('');
    const show = ref(false);
    const type = ref({
      name: t('identity_card'),
      value: 1
    });
    const actions = [{
      name: t('identity_card'),
      value: 1
    }, {
      name: t('passport'),
      value: 2
    }];
    const back = () => {
      router.go(-1);
    };
    const typeSelect = action => {
      type.value = action;
    };
    const afterReadFront = file => {
      const formData = new FormData();
      formData.append('file', file.file);
      uploadFile(formData).then(({
        data
      }) => {
        frontImg.value = data;
      });
    };
    const afterReadBack = file => {
      const formData = new FormData();
      formData.append('file', file.file);
      uploadFile(formData).then(({
        data
      }) => {
        backImg.value = data;
      });
    };
    return (_ctx, _cache) => {
      const _component_van_nav_bar = _resolveComponent("van-nav-bar");
      const _component_van_icon = _resolveComponent("van-icon");
      const _component_van_field = _resolveComponent("van-field");
      const _component_van_uploader = _resolveComponent("van-uploader");
      const _component_van_button = _resolveComponent("van-button");
      const _component_van_action_sheet = _resolveComponent("van-action-sheet");
      return _openBlock(), _createElementBlock(_Fragment, null, [_createVNode(_component_van_nav_bar, {
        "left-arrow": "",
        title: _ctx.$t('real_name_authentication'),
        placeholder: "",
        fixed: "",
        onClickLeft: back
      }, null, 8, ["title"]), authData.value === 3 || authData.value === 2 ? (_openBlock(), _createElementBlock("div", _hoisted_1, [_createElementVNode("p", _hoisted_2, _toDisplayString(_ctx.$t('document_type')), 1), _createElementVNode("div", {
        class: "auth-form__item1",
        onClick: _cache[0] || (_cache[0] = $event => show.value = true)
      }, [_createElementVNode("span", null, _toDisplayString(type.value['name']), 1), _createVNode(_component_van_icon, {
        name: "arrow"
      })]), _createElementVNode("p", _hoisted_3, _toDisplayString(_ctx.$t('real_name')), 1), _createElementVNode("div", _hoisted_4, [_createVNode(_component_van_field, {
        modelValue: formData.value.real_name,
        "onUpdate:modelValue": _cache[1] || (_cache[1] = $event => formData.value.real_name = $event),
        placeholder: _ctx.$t('please_enter_real_name'),
        class: "br-a__8"
      }, null, 8, ["modelValue", "placeholder"])]), _createElementVNode("p", _hoisted_5, _toDisplayString(_ctx.$t('document_number')), 1), _createElementVNode("div", _hoisted_6, [_createVNode(_component_van_field, {
        modelValue: formData.value.idcret_num,
        "onUpdate:modelValue": _cache[2] || (_cache[2] = $event => formData.value.idcret_num = $event),
        placeholder: _ctx.$t('please_enter_document_number'),
        class: "br-a__8"
      }, null, 8, ["modelValue", "placeholder"])]), _createElementVNode("p", _hoisted_7, _toDisplayString(_ctx.$t('document_photo')), 1), _createElementVNode("div", _hoisted_8, [_createVNode(_component_van_uploader, {
        "after-read": afterReadFront
      }, {
        default: _withCtx(() => [_createElementVNode("div", _hoisted_9, [frontImg.value ? (_openBlock(), _createElementBlock("img", {
          key: 0,
          src: _unref(IMG_URL) + frontImg.value,
          alt: ""
        }, null, 8, _hoisted_10)) : (_openBlock(), _createElementBlock("img", _hoisted_11)), _createElementVNode("p", null, _toDisplayString(_ctx.$t('front')), 1)])]),
        _: 1
      }), _createVNode(_component_van_uploader, {
        "after-read": afterReadBack
      }, {
        default: _withCtx(() => [_createElementVNode("div", _hoisted_12, [backImg.value ? (_openBlock(), _createElementBlock("img", {
          key: 0,
          src: _unref(IMG_URL) + backImg.value,
          alt: ""
        }, null, 8, _hoisted_13)) : (_openBlock(), _createElementBlock("img", _hoisted_14)), _createElementVNode("p", null, _toDisplayString(_ctx.$t('back')), 1)])]),
        _: 1
      })]), _createVNode(_component_van_button, {
        round: "",
        block: "",
        color: "#FC72FF",
        onClick: _addAuth
      }, {
        default: _withCtx(() => [_createTextVNode(_toDisplayString(_ctx.$t('submit')), 1)]),
        _: 1
      })])) : _createCommentVNode("", true), _createElementVNode("div", _hoisted_15, [_createElementVNode("div", _hoisted_16, [authData.value === 0 ? (_openBlock(), _createElementBlock("img", _hoisted_17)) : _createCommentVNode("", true), authData.value === 1 ? (_openBlock(), _createElementBlock("img", _hoisted_18)) : _createCommentVNode("", true), authData.value === 0 ? (_openBlock(), _createElementBlock("span", _hoisted_19, _toDisplayString(_ctx.$t('under_review')), 1)) : _createCommentVNode("", true), authData.value === 1 ? (_openBlock(), _createElementBlock("span", _hoisted_20, _toDisplayString(_ctx.$t('certification_passed')), 1)) : _createCommentVNode("", true)])]), _createVNode(_component_van_action_sheet, {
        show: show.value,
        "onUpdate:show": _cache[3] || (_cache[3] = $event => show.value = $event),
        onSelect: typeSelect,
        actions: actions,
        "cancel-text": _ctx.$t('cancel'),
        "close-on-click-action": "",
        onCancel: _cache[4] || (_cache[4] = $event => show.value = false)
      }, null, 8, ["show", "cancel-text"])], 64);
    };
  }
};